<template>
  <v-dialog v-model="show" max-width="550px" max-height="551px" persistent>
    <v-card class="px-0 pb-0 pt-0">
      <v-card-title class="px-4">
        <span class="text-h5" :style="'color: #182026;font-weight:500;font-size:20px !important'">{{ titulo }}</span>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="close">
          <v-icon class="ma-0">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="ma-0 pa-4">
        <v-form ref="form" v-model="valid" lazy-validation class="pa-0 ma-0">
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-text-field
                id="idCodigo"
                v-model="incidence.code"
                label="Código *"
                :rules="[rules.requiredCode, rules.digit, rules.exist]"
                required
                v-mask="'##'"
                maxlength="2"
                minlength="2"
                :hint="codigoHint"
                persistent-hint
                dense
                outlined
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" class="pt-4 pb-0">
              <v-text-field
                id="idNome"
                v-model="incidence.name"
                :rules="[rules.requiredName]"
                hide-details="auto"
                label="Nome *"
                dense
                outlined
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" class="pt-4 pb-0">
              <v-list-item class="pb-0 pl-0 pr-0">
                <v-combobox hide-details="auto" v-model="incidence.group" :items="groups" label="Grupo" outlined dense>
                  <template slot="item" slot-scope="data">
                    <v-tooltip bottom v-if="data.item.length > 62" max-width="250" max-height="56">
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                          {{ start_and_end(data.item, 62) }}
                        </span>
                      </template>
                      <span>{{ data.item }}</span>
                    </v-tooltip>
                    <span v-else> {{ start_and_end(data.item, 62) }}</span>
                  </template>
                </v-combobox>
              </v-list-item>
            </v-col>
            <v-col cols="6" class="pr-2 pt-4 pb-4">
              <v-menu v-model="modalPickerStart" :close-on-content-click="false" :disabled="isHability">
                <v-date-picker
                  v-model="incidence.start"
                  no-title
                  @input="modalPickerStart = false"
                  type="month"
                ></v-date-picker>
                <template v-slot:activator="{ on }">
                  <v-text-field
                    id="textPicker"
                    :disabled="isHability"
                    :rules="[rules.requiredStart]"
                    v-model="dateStartFormated"
                    v-on="on"
                    outlined
                    label="Início *"
                    append-icon="mdi-calendar"
                    readonly
                    dense
                    clearable
                    hide-details="auto"
                    v-mask="'##/####'"
                  >
                  </v-text-field>
                </template>
              </v-menu>
            </v-col>
            <v-col cols="6" class="pl-2 pt-4 pb-4">
              <v-menu v-model="modalPickerEnd" :close-on-content-click="false" :disabled="isHability">
                <v-date-picker
                  v-model="incidence.end"
                  no-title
                  @input="modalPickerEnd = false"
                  type="month"
                ></v-date-picker>
                <template v-slot:activator="{ on }">
                  <v-text-field
                    id="textPicker"
                    :disabled="isHability"
                    v-model="dateEndFormated"
                    v-on="on"
                    outlined
                    label="Término"
                    append-icon="mdi-calendar"
                    readonly
                    dense
                    clearable
                    hide-details="auto"
                    v-mask="'##/####'"
                  >
                  </v-text-field>
                </template>
              </v-menu>
            </v-col>
          </v-row>
        </v-form>
        <div class="text-left pt-8" style="font-size: 14px; color: #575767; font-weight: 400">
          * Campos de preenchimento obrigatório
        </div>

        <div class="text-red pl-2" style="color: #610006" v-if="invalidForm">
          Preencher todos os campos obrigatórios
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="close" id="btnCancel" class="btn-ag-tertiary"> Cancelar</v-btn>
        <v-btn @click="save()" class="btn-ag-primary" :disabled="isEnableSave" id="btnConfirmOredit">
          {{ labelBotao }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import eSocialService from '@/services/eSocialService.js';
import { mapGetters, mapActions } from 'vuex';
import { eventBus } from '../../../../../main.js';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';

export default {
  name: 'ModalIncidenceIRRFNew',
  props: ['incidences'],

  data() {
    return {
      valid: true,
      invalidForm: false,
      codigoHint: 'Preencha até dois números (0-9)',
      incidence: {
        code: '',
        name: '',
        group: '',
        start: '',
        end: '',
      },
      titulo: 'Adicionar incidência',
      labelBotao: 'Salvar',
      show: false,
      persistentCodigo: true,
      dateMessageError: '',
      groups: [
        'Sem grupo',
        'Rendimento tributável (base de cálculo do IR)  ',
        'Retenção do IRRF efetuada sobre',
        'Dedução do rendimento tributável do IRRF',
        'Rendimento não tributável ou isento do IRRF',
        'Demandas judiciais',
        'Exigibilidade suspensa - Rendimento tributável (base de cálculo do IR)',
        'Exigibilidade suspensa - Retenção do IRRF efetuada sobre',
        'Exigibilidade suspensa - Dedução da base de cálculo do IRRF',
        'Compensação judicial',
      ],
      existentCode: true,
      isHability: false,
      modalPickerStart: false,
      modalPickerEnd: false,
      rules: {
        requiredCode: (code) => !!code || 'Preencha até dois números (0-9)',
        requiredName: (name) => !!name || 'Preencha um nome',
        requiredStart: (start) => !!start || 'Preencha o início',
        digit: (code) => /^\d{2}$/.test(code) || 'Preencha até dois números (0-9)',
        exist: (code) => !this.codeExist(code) || 'Este código já existe! Preencha outro código',
      },
      toast: {
        message: '',
        type: '',
        show: false,
        topToast: 0,
      },
    };
  },

  computed: {
    ...mapGetters({
      userInfo: 'user/userInfo',
    }),
    isEnableSave() {
      let count = 0;
      if (![undefined, null].includes(this.incidence.code)) {
        if (!this.isEmpty(this.incidence.code)) {
          ++count;
        }
      }
      if (![undefined, null].includes(this.incidence.name)) {
        if (!this.isEmpty(this.incidence.name)) {
          ++count;
        }
      }

      if (![undefined, null].includes(this.incidence.start)) {
        if (!this.isEmpty(this.incidence.start)) {
          ++count;
        }
      }
      if (this.existentCode) {
        --count;
      }

      if (count === 3) {
        return false;
      } else {
        return true;
      }
    },
    dateStartFormated: {
      get() {
        if (this.incidence.start) {
          var dataFormatada = this.formatDate(this.incidence.start);
          return dataFormatada;
        } else {
          return null;
        }
      },
      set(data) {
        return data;
      },
    },
    dateEndFormated: {
      get() {
        if (this.incidence.end) {
          var dataFormatada = this.formatDate(this.incidence.end);
          return dataFormatada;
        } else {
          return null;
        }
      },
      set(data) {
        return data;
      },
    },
    allowedInitialDate() {
      if (![undefined, null, ''].includes(this.incidence.end)) {
        return (val) => {
          return this.incidence.end > val;
        };
      }
      return null;
    },
    allowedFinalDate() {
      if (![undefined, null, ''].includes(this.incidence.start)) {
        return (val) => {
          return this.incidence.start < val;
        };
      }
      return null;
    },
  },

  created() {
    this.init();
  },
  validations: {
    incidence: {
      code: { required }, // Matches this.firstName
      name: { required }, // Matches this.lastName
      start: {
        required, // Matches this.contact.email
      },
    },
  },
  methods: {
    isEmpty(str) {
      if (str.trim().length === 0) {
        return true;
      } else {
        return false;
      }
    },
    checkIsActive(dateEnd) {
      var partes = dateEnd.split('-');
      var ano = parseInt(partes[0], 10);
      var mes = parseInt(partes[1], 10);
      var dataValidadeObj = new Date(ano, mes, '1');
      dataValidadeObj.setDate(dataValidadeObj.getDate() - 1);
      var dataAtual = new Date();
      if (dataAtual <= dataValidadeObj) {
        return 'Ativo';
      } else {
        return 'Inativo';
      }
    },
    async save() {
      this.$v.incidence.$touch();

      if (this.$v.incidence.$error || this.$v.incidence.$pending) {
        this.$v.$reset();
        this.invalidForm = true;
        return;
      }
      let situation = this.incidence.end ? this.checkIsActive(this.incidence.end) : 'Ativo';
   
      if (this.incidence.group === 'Sem grupo' || this.incidence.group === undefined) {
        this.incidence.group = '';
      }
      let incidenceIRRF = {
        code: this.incidence.code,
        group: this.incidence.group,
        name: this.incidence.name,
        situation: situation,
        start: this.dateStartFormated,
        end: this.dateEndFormated,
        user: `${this.userInfo.firstName} ${this.userInfo.lastName}`,
      };
      const response = await eSocialService.rubricAutomationConfig.incidenceIRRFSave(incidenceIRRF);
      if (response) {
        (this.incidence.code = ''),
          (this.incidence.name = ''),
          (this.incidence.group = ''),
          (this.incidence.start = ''),
          (this.incidence.end = ''),
          this.$emit('refresh');
        this.close();
        this.showToast('Incidência adicionada com sucesso!', 'success', 78);
      } else {
        this.showToast('Erro ao adicionar incidência ', 'warning', 78);
      }
    },
    codeExist(code) {
      if (this.incidences.some((item) => item.code === code)) {
        this.existentCode = true;
        return true;
      } else {
        this.existentCode = false;
        return false;
      }
    },
    init() {
      this.$on('open', this.open);
      this.$on('close', this.close);
    },

    async open() {
      this.incidence = {};
      this.titulo = 'Adicionar incidência';
      this.labelBotao = 'Salvar';
      this.show = true;
    },

    close() {
      this.incidence = {};
      this.invalidForm = false;

      this.$refs.form.resetValidation();
      this.show = false;
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.toString().split('-');
      return `${month}/${year}`;
    },
    showToast(message, type, topToast) {
      this.toast.show = true;
      this.toast.type = type;
      this.toast.message = message;
      this.toast.topToast = topToast;
      eventBus.$emit('openToast', this.toast);
    },
    start_and_end(str, lengthStr) {
      if (str.length > lengthStr) {
        return str.substr(0, lengthStr) + '...';
      }
      return str;
    },
  },
};
</script>
<style scoped lang="scss">
@import './src/design/components/input.scss';
@import './src/design/components/select.scss';
@import './src/design/components/datepicker.scss';

.v-card__actions {
  padding: 16px !important;
}
::v-deep .v-text-field__details .v-messages {
  text-align: right !important;
}
::v-deep .v-text-field__details .v-messages__message {
  background: none !important;
}
::v-deep .v-text-field__details .v-messages.theme--light.error--text {
  text-align: left !important;
  background: url('https://api.iconify.design/bxs/error-circle.svg?color=rgba(150, 45, 51, 1)') no-repeat top left !important;
}
</style>
