var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "550px", "max-height": "551px", persistent: "" },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "px-0 pb-0 pt-0" },
        [
          _c(
            "v-card-title",
            { staticClass: "px-4" },
            [
              _c(
                "span",
                {
                  staticClass: "text-h5",
                  style:
                    "color: #182026;font-weight:500;font-size:20px !important",
                },
                [_vm._v(_vm._s(_vm.titulo))]
              ),
              _c("v-spacer"),
              _c(
                "v-btn",
                { attrs: { icon: "", dark: "" }, on: { click: _vm.close } },
                [_c("v-icon", { staticClass: "ma-0" }, [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-text",
            { staticClass: "ma-0 pa-4" },
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  staticClass: "pa-0 ma-0",
                  attrs: { "lazy-validation": "" },
                  model: {
                    value: _vm.valid,
                    callback: function ($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid",
                  },
                },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "pb-0", attrs: { cols: "12" } },
                        [
                          _c("v-text-field", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: "##",
                                expression: "'##'",
                              },
                            ],
                            attrs: {
                              id: "idCodigo",
                              label: "Código *",
                              rules: [
                                _vm.rules.requiredCode,
                                _vm.rules.digit,
                                _vm.rules.exist,
                              ],
                              required: "",
                              maxlength: "2",
                              minlength: "2",
                              hint: _vm.codigoHint,
                              "persistent-hint": "",
                              dense: "",
                              outlined: "",
                              "hide-details": "auto",
                            },
                            model: {
                              value: _vm.incidence.code,
                              callback: function ($$v) {
                                _vm.$set(_vm.incidence, "code", $$v)
                              },
                              expression: "incidence.code",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "pt-4 pb-0", attrs: { cols: "12" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              id: "idNome",
                              rules: [_vm.rules.requiredName],
                              "hide-details": "auto",
                              label: "Nome *",
                              dense: "",
                              outlined: "",
                            },
                            model: {
                              value: _vm.incidence.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.incidence, "name", $$v)
                              },
                              expression: "incidence.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "pt-4 pb-0", attrs: { cols: "12" } },
                        [
                          _c(
                            "v-list-item",
                            { staticClass: "pb-0 pl-0 pr-0" },
                            [
                              _c("v-combobox", {
                                attrs: {
                                  "hide-details": "auto",
                                  items: _vm.groups,
                                  label: "Grupo",
                                  outlined: "",
                                  dense: "",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "item",
                                    fn: function (data) {
                                      return [
                                        data.item.length > 62
                                          ? _c(
                                              "v-tooltip",
                                              {
                                                attrs: {
                                                  bottom: "",
                                                  "max-width": "250",
                                                  "max-height": "56",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function ({
                                                        on,
                                                        attrs,
                                                      }) {
                                                        return [
                                                          _c(
                                                            "span",
                                                            _vm._g(
                                                              _vm._b(
                                                                {},
                                                                "span",
                                                                attrs,
                                                                false
                                                              ),
                                                              on
                                                            ),
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.start_and_end(
                                                                      data.item,
                                                                      62
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(_vm._s(data.item)),
                                                ]),
                                              ]
                                            )
                                          : _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.start_and_end(
                                                      data.item,
                                                      62
                                                    )
                                                  )
                                              ),
                                            ]),
                                      ]
                                    },
                                  },
                                ]),
                                model: {
                                  value: _vm.incidence.group,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.incidence, "group", $$v)
                                  },
                                  expression: "incidence.group",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "pr-2 pt-4 pb-4", attrs: { cols: "6" } },
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                "close-on-content-click": false,
                                disabled: _vm.isHability,
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function ({ on }) {
                                    return [
                                      _c(
                                        "v-text-field",
                                        _vm._g(
                                          {
                                            directives: [
                                              {
                                                name: "mask",
                                                rawName: "v-mask",
                                                value: "##/####",
                                                expression: "'##/####'",
                                              },
                                            ],
                                            attrs: {
                                              id: "textPicker",
                                              disabled: _vm.isHability,
                                              rules: [_vm.rules.requiredStart],
                                              outlined: "",
                                              label: "Início *",
                                              "append-icon": "mdi-calendar",
                                              readonly: "",
                                              dense: "",
                                              clearable: "",
                                              "hide-details": "auto",
                                            },
                                            model: {
                                              value: _vm.dateStartFormated,
                                              callback: function ($$v) {
                                                _vm.dateStartFormated = $$v
                                              },
                                              expression: "dateStartFormated",
                                            },
                                          },
                                          on
                                        )
                                      ),
                                    ]
                                  },
                                },
                              ]),
                              model: {
                                value: _vm.modalPickerStart,
                                callback: function ($$v) {
                                  _vm.modalPickerStart = $$v
                                },
                                expression: "modalPickerStart",
                              },
                            },
                            [
                              _c("v-date-picker", {
                                attrs: { "no-title": "", type: "month" },
                                on: {
                                  input: function ($event) {
                                    _vm.modalPickerStart = false
                                  },
                                },
                                model: {
                                  value: _vm.incidence.start,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.incidence, "start", $$v)
                                  },
                                  expression: "incidence.start",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "pl-2 pt-4 pb-4", attrs: { cols: "6" } },
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                "close-on-content-click": false,
                                disabled: _vm.isHability,
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function ({ on }) {
                                    return [
                                      _c(
                                        "v-text-field",
                                        _vm._g(
                                          {
                                            directives: [
                                              {
                                                name: "mask",
                                                rawName: "v-mask",
                                                value: "##/####",
                                                expression: "'##/####'",
                                              },
                                            ],
                                            attrs: {
                                              id: "textPicker",
                                              disabled: _vm.isHability,
                                              outlined: "",
                                              label: "Término",
                                              "append-icon": "mdi-calendar",
                                              readonly: "",
                                              dense: "",
                                              clearable: "",
                                              "hide-details": "auto",
                                            },
                                            model: {
                                              value: _vm.dateEndFormated,
                                              callback: function ($$v) {
                                                _vm.dateEndFormated = $$v
                                              },
                                              expression: "dateEndFormated",
                                            },
                                          },
                                          on
                                        )
                                      ),
                                    ]
                                  },
                                },
                              ]),
                              model: {
                                value: _vm.modalPickerEnd,
                                callback: function ($$v) {
                                  _vm.modalPickerEnd = $$v
                                },
                                expression: "modalPickerEnd",
                              },
                            },
                            [
                              _c("v-date-picker", {
                                attrs: { "no-title": "", type: "month" },
                                on: {
                                  input: function ($event) {
                                    _vm.modalPickerEnd = false
                                  },
                                },
                                model: {
                                  value: _vm.incidence.end,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.incidence, "end", $$v)
                                  },
                                  expression: "incidence.end",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "text-left pt-8",
                  staticStyle: {
                    "font-size": "14px",
                    color: "#575767",
                    "font-weight": "400",
                  },
                },
                [_vm._v(" * Campos de preenchimento obrigatório ")]
              ),
              _vm.invalidForm
                ? _c(
                    "div",
                    {
                      staticClass: "text-red pl-2",
                      staticStyle: { color: "#610006" },
                    },
                    [_vm._v(" Preencher todos os campos obrigatórios ")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "btn-ag-tertiary",
                  attrs: { text: "", id: "btnCancel" },
                  on: { click: _vm.close },
                },
                [_vm._v(" Cancelar")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "btn-ag-primary",
                  attrs: { disabled: _vm.isEnableSave, id: "btnConfirmOredit" },
                  on: {
                    click: function ($event) {
                      return _vm.save()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.labelBotao) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }